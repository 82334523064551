import { BaseMenu } from '../models/menu/BaseMenu';

//import { ValueDisplayString } from '../models/valueDisplayString';

export enum RouteUrl {
  dashboard = 'app/dashboard',

  browserUpgrade = 'browser-upgrade',

  //#region ACTIVITY
  authorizations = 'app/authorizations',
  reservations = 'app/reservation',
  chargingSessions = 'app/charging-sessions',
  transaction = 'app/transactions',
  razorpayTransaction = 'app/razorpay-transaction',
  reservation = 'app/reservation',
  //#endregion

  //#region ASSETS
  chargePoints = 'app/charge-points',
  evses = 'app/evse',
  connectors = 'app/connectors',
  chargingZones = 'app/charging-zones',
  locations = 'app/locations',
  locationImages = 'app/location-images',
  //#endregion

  //#region CRM
  users = 'app/users',
  rfidTags = 'app/rfid-tags',
  //#endregion

  //#region public evse qr code
  publicevse = '/public/evse/',
  //#endregion

  //#region PARTNERS
  partners = 'app/partners',
  partnerInvites = 'app/partner-invites',
  partnerContracts = 'app/partner-contracts',
  //#endregion

  //#region ANALYTICS
  reports = 'app/reports',
  //#endregion

  //#region ANALYTICS
  device = 'app/device',
  //#endregion

  //#region CONFIGURATION
  admins = 'app/admins',
  client = 'app/clients',
  clientDetail = 'app/client-detail',
  //#endregion

  //#region AUTHENTICATION
  login = 'auth/login',
  forgotPassword = 'auth/forgot-password',
  resetPassword = 'auth/reset-password',
  changePassword = 'app/change-password',
  //#endregion

  //#region LOGS
  statusLogs = 'app/status-logs',
  communicationLogs = 'app/communication-logs',
  reqResLogs = 'app/request-response-log',
  //#endregion

  //#region CONFIGURATIONS
  configurations = 'app/configurations',
  //#endregion

  //#region TARIFFS
  tariffs = 'app/tariff',
  //#endregion

  //#region RESERVATION TARIFFS
  reservationTariff = 'app/reservation-tariff',
  //#endregion

  //#region CURRENCY
  currency = 'app/currency',
  //#endregion

  //#region PAYMENT PROCESSOR
  paymentProcessor = 'app/payment-processor',
  //#endregion

  //#region RAZORPAY TRANSACTION
  razorPay = 'app/razorpay-transaction',
  //#endregion

  //#region PAYMENT PROCESSOR
  wallet = 'app/wallet',
  //#endregion

  //#region ROUTE-PREFIX
  new = 'new',
  update = 'update',
  partnerAdmin = 'partnerAdmin',
  importCSV = 'import-csv',
  //#endregion

  //#region partner company detail
  partnerCompanyDetail = 'app/partner-company-detail',
  //#endregion

  //#region SigFox Data
  sigFoxData = 'app/sigfox-data',
  //#endregion

  //#region FAQ
  faq = 'app/faq',
  faqWebView = 'list',
  //#endregion

  //#region Amenity
  amenity = 'app/amenity',

  //#endregion

  smstemplate = 'app/sms-template',
}

export enum LocalStorageKeys {

  loginResponse = 'loginResponseVM',

}



// export const DasboardRangeValues: ValueDisplayString[] = [
//     {
//         display: 'Month To Date',
//         value: DasboardRange.MONTH_TO_DATE
//     },
//     {
//         display: 'Quarter To Date',
//         value: DasboardRange.QUARTER_TO_DATE
//     },
//     {
//         display: 'Year To Date',
//         value: DasboardRange.YEAR_TO_DATE
//     },
//     {
//         display: 'Today',
//         value: DasboardRange.TODAY
//     },
//     {
//         display: '30 Days',
//         value: DasboardRange.DAYS_30
//     },
//     {
//         display: '60 Days',
//         value: DasboardRange.DAYS_60
//     },
//     {
//         display: '365 Days',
//         value: DasboardRange.DAYS_365
//     }
// ];

export enum UrlParameter {
  onlyEnabled = 'onlyEnabled',
  clientId = 'clientId',
  roleId = 'roleId',
  siteId = 'siteId',
  floorId = 'floorId',
  roomId = 'roomId',
  deviceTypeCode = 'deviceTypeCode',
  deviceTypeId = 'deviceTypeId',
  forAlarm = 'forAlarm',
  deviceId = 'deviceId',
  isIncludeFunction = 'isIncludeFunction',
  alarmId = 'alarmId',
  tagId = 'tagId',
  schedulerId = 'schedulerId',
  tankId = 'tankId',
  tankTypeDetailId = 'tankTypeDetailId',
  ipAddress = 'ipAddress',
  locimageId = 'chargePointLocationImageId',
  chargingZoneId = 'chargingZoneId',
  chargingZoneStatus = 'status',
  authorizationId = 'authorizationId',
  isAll = 'isAll',

  // EV QC
  productId = "productId",
  productType = "productType",
  srTestMasterId = "srTestMasterId",
  srTestDeviceId = "srTestDeviceId",
  testCaseType = "testCaseType",
  isForAdd = "isForAdd",
  chargePointSessionId = "chargePointSessionId",
  skey = "skey",
  productserialDataForFunctional = "productserialDataForFunctional",
  userId = 'userId',
  productSerialSkey = 'productSerialSkey',
  cpSr = 'cpSrValue',
  productSerialNumberSkey = 'productSerialNumberSkeyValue'

}

export enum ErrorCode {
  badRequest = 400,
  unauthorizedRequest = 401,
  accessDenied = 403,
  internalServer = 500,
  notfound = 404,
  sessionExpired = 440,
}
export enum ExcelFileHeader {
  dsoNumber = "DSO Number",
  productCode = "Product Code",
  productSerialNumber = "Product Serial Number",
  macAddress = "MAC Address",
  firmwareVersion = "Firmware Version",
  serverType = "Server Type",
}

export enum ErrorMessage {
  invalidRefreshToken = 'Invalid refresh token',
  sessionExpired = 'Session expired. Please log in again',
}

export enum ToastMessages {
  MESSAGE_API_RESPONSE_ERROR = 'Error in API Response',
}

export enum RequestResponseMessageType {
  Request = '2',
  Response = '3',
  Error = '4',
}




export enum MenuCodes {
  dashboard = 'dashboard',
  client_management = 'client_management',
  user_management = 'user_management',
}

export enum RouteUrl {
  //#region role
  role = '/app/users/role/list',
  addRole = '/app/users/role/add',
  //#endregion

  //#region dashboard
  userDashboard = '/hes/userDashboard',
  meterDashboard = '/hes/meter/meterDashboard',
  Getmeterlist = '/hes/meter/list',
  bulkupload = '/hes/bulkupload',
  recharge = '/hes/meterOperation/recharge',
  //#endregion

  addMeter = '/hes/meter/add',
  listMeter = '/hes/meter/list',

  dculist = '/hes/dcu/list',
  dcuadd = '/hes/dcu/add',

  meteradd = '/hes/meter/add',

  zonelist = '/hes/zone/list',
  zoneAdd = '/hes/zone/add',
  circle = '/hes/zone/circle',

  schedulerGrouplist = '/hes/schedulerGroup/list',
  schedulerGroupAdd = '/hes/scheduler/schedulerGroup',
  schedulerGroupEdit = '/hes/schedulerGroup/edit',

  schedulerTemplatelist = '/hes/schedulerTemplate/list',
  schedulerTemplateAdd = '/hes/scheduler/schedulerTemplate',
  schedulerTemplateedit = '/hes/schedulerTemplate/edit',

  readerlist = '/hes/reader/list',
  readeradd = '/hes/reader/add',

  userdetails = '/hes/userdetails',
  changepassword = '/hes/changepassword',

  addTemplate = 'hes/template/add',
  listTemplate = 'hes/template/list',

  addTemplateRolemapping = 'hes/templatemapping/add',
  listTemplateRolemapping = 'hes/templatemapping/list',

  consumerbulkupload = 'hes/consumerbulkupload',
}



export enum HESDateTime {
  DateTimeFormat = 'dd/MM/yyyy HH:mm',
}

export enum QCTestType {
  Physical = "physical",
  General = "general",
  Functional = "functional",
  Undefine = "undefine",
  Triage = "triage",
  DeviceConfiguration = "configuration",

}

export enum QCStage {
  Triage = "triage",
  Physical = "physical",
  Functional = "functional",
  Rejected = "rejected",
  DeviceConfiguration = "configuration",


}

export enum TestResult {
  Pending = "pending",
  Passed = "passed",
  Failed = "failed"
}

export enum ConnectorAmpereType {
  A16 = "A16",
  A32 = "A32",
  General = "general",
}
export enum ConnectorType {
  Socket = "socket",
  Gun = "gun",

}
export enum ConnectorPhase {
  Single = "single",
  Three = "three",
}

export enum CommandType {
  COMMAND_LIST_STATUS_NOTIFICATION = "COMMAND_LIST_STATUS_NOTIFICATION",
  COMMAND_LIST_START_TRANSACTION = "COMMAND_LIST_START_TRANSACTION",
  CONNECTION_STATUS_AVAILABLE = "CONNECTION_STATUS_AVAILABLE",
  CHARGE_POINT_PRESENCE = "CHARGE_POINT_PRESENCE",
  STATUS_NOTIFICATION = "STATUS_NOTIFICATION",
  METER_VALUES = "METER_VALUES",
  COMMAND_LIST_STOP_TRANSACTION = "COMMAND_LIST_STOP_TRANSACTION",

}
export enum DeviceStatusType {
  CHARGING = "CHARGING",
  FINISHING = "FINISHING",
  AVAILABLE = "AVAILABLE",
  FAULTED = "FAULTED"
}

export enum QrCodeParameters {
  CHARGEPOINT = "CHARGEPOINT",
  EVSEID = "EVSEID",
  QRCODEID = "QRCODEID",
}
export enum CONNECTION_STATUS {
  CONNECTION_STATUS_AVAILABLE = "CONNECTION_STATUS_AVAILABLE",
  CONNECTION_STATUS_LONG_TERM_UNAVAILABLE = "CONNECTION_STATUS_LONG_TERM_UNAVAILABLE",
  CONNECTION_STATUS_UNAVAILABLE = "CONNECTION_STATUS_UNAVAILABLE",
}
export enum CHARGEPOINT_ACTION_STATUS {
  CP_ACTION_STATUS_ACCEPTED = "CP_ACTION_STATUS_ACCEPTED",
  CP_ACTION_STATUS_AVAILABLE = "CP_ACTION_STATUS_AVAILABLE",
  CP_ACTION_STATUS_BLOCKED = "CP_ACTION_STATUS_BLOCKED",
  CP_ACTION_STATUS_CHARGING = "CP_ACTION_STATUS_CHARGING",
  CP_ACTION_STATUS_FAILED = "CP_ACTION_STATUS_FAILED",
  CP_ACTION_STATUS_PREPARING = "CP_ACTION_STATUS_PREPARING",
  CP_ACTION_STATUS_REJECTED = "CP_ACTION_STATUS_REJECTED",
  CP_ACTION_STATUS_REQUESTED = "CP_ACTION_STATUS_REQUESTED",
  CP_ACTION_STATUS_RESERVED = "CP_ACTION_STATUS_RESERVED",
  CP_ACTION_STATUS_UNAVAILABLE = "CP_ACTION_STATUS_UNAVAILABLE",
}

export enum CHARGING_LABLE_CONSTANT {
  CP_ACTION_STATUS_ACCEPTED = "Charging request has been accepted!",
  CP_ACTION_STATUS_CHARGING = "Charging started!",
  CP_ACTION_STATUS_FAILED = "Charging request has been failed!",
  CP_ACTION_STATUS_PREPARING = "Charger is preparing for the charging!",
  CP_ACTION_STATUS_REJECTED = "Charging request has been rejected!",
  CP_ACTION_STATUS_RESERVED = "Charger is reserved!",
  CP_ACTION_STATUS_UNAVAILABLE = "Charger is unavailable!",
}

export enum ServerTypes {
  SLS = "sls",
  VerdeMobility = "verdemobility"
}
